
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    body::-webkit-scrollbar {
        width: 10px;
        background: rgb(32, 32, 32);
    }
    
    body::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background: var(--scrollbarBG);
    }
    
    body::-webkit-scrollbar-thumb {
        background-color: #0D3E0F;
        outline: 1px solid #197B1D;
        box-shadow: 0 -100vh 0 100vh var(--shadow), 0 0 15px 5px rgb(0, 0, 0);
    }  

    .NavBar-icon {
        @apply relative flex items-center justify-center 
        h-20 w-20 mt-2 mb-2 mx-auto
      bg-primary hover:bg-secondary text-7xl
      text-thirdary hover:text-white border border-primary
        hover:rounded-xl rounded-3xl
        transition-all duration-300 ease-linear
        cursor-pointer shadow-lg ;
    }
    .NavBar-tooltip {
        @apply absolute w-auto p-4 ml-12 min-w-max left-14 rounded-md shadow-md select-none
        text-white bg-gray-900 
        text-xl font-bold 
        transition-all duration-100 scale-0 origin-left;
      }
    .th-cell{
        @apply table-cell font-bold align-middle  text-white
        p-3 border border-white
        bg-secondary
    }
    .td-cell{
        @apply px-8 py-6 border border-white 
        text-black align-middle text-center
        justify-center select-none
    }
    input[type="number"] {
        @apply border rounded w-5 text-xs m-0 outline-none appearance-none
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; 
    }
    input[type="text"] {
        @apply block mx-0
    }
}